
export const pagos = () => {
    return {
        pagos_actualizado: 'Plan de Pagos actualizado',
        cobrador: 'Cobrador (usuario)',
        editar: 'Editar pago',
        borrar: 'Eliminar pago',
        importe_total: 'Importe Total',
        saldo_total: 'Saldo Total',
        add_cuota: 'Cuota {cuota} agregada',
        add_pago: 'Pago agregado',
        modified_pago: 'Pago modificado',
        add_companypayment: 'Cobranza percibida agregada',
        modified_companypayment: 'Cobranza percibida modificada',
        modified_cuota: 'Cuota {cuota} modificada',
        pagos_cia_tab: 'Pagos en Compañía',
        validate_prima_comisionable: 'Debe ingresar el premio de la póliza para el cálculo de comisiones',
        pagos_client_tab: 'Cobranzas Personales al Cliente',
        pagos_cia: 'Pagos realizados a la Compañía',
        pagos_client: 'Cobranzas personales (percibidas)',
        confirm_load_concepts: '¿Querés cargar {count} cuotas automáticamente dividiendo el premio de la póliza?',
        deuda_exigible: 'Deuda exigible',
        error_positive_amount: 'Importe inválido',
        error_positive_amount_detail: 'El importe ingresado no puede ser positivo',
        error_amount_exceeds_target: 'Importe inválido',
        error_amount_exceeds_target_detail: 'El importe ingresado no puede ser mayor al saldo de la cuota destino',
        saldar_cuota_particular: 'Saldar cuota particular (NC)',
        fecha_vencimiento_short: 'Fec. Venc.',
        fecha_rendicion_short: 'Fec. Rend.',
        fecha_rendicion: 'Fecha rendición',
        fecha_pago_short: 'Fec. Pago',
        filter_endoso: 'Filtrar por endoso',
        verpendientes: 'Solo pendientes de pago',
        cuota_endoso: 'Cuota / Endoso',
        plan_cia: 'Plan de pagos en compañía',
        plan_client: 'Plan de cobranzas Personales (pendientes)',
        copy_concepts: 'Copiar Plan de Cia',
        confirm_copy_plan: 'Se van a reemplazar las cobranzas pendientes cargadas actualmente. ¿Continuar?',
        add_cpc: 'Agregar cuota',
        add_clientpc: 'Agregar cobranza personal pendiente',
        add_cp: 'Cargar pago realizado a la compañía',
        add_clientcp: 'Cargar cobranza personal percibida',
        edit_cp: 'Editar pago realizado a la compañía',
        edit_clientcp: 'Editar cobranza personal percibida',
        recalcular_impagas: 'Si está marcado, se calcula  automáticamente el importe de esta cuota y se recalcula el importe de las cuotas con saldo pendiente. Desmarcado, ingresa importe en forma manual.',
        recalcular_impagas_short: 'Calcular importes automáticamente',
        no_recalcular_impagas: 'NO se recalculan las cuotas impagas. Debe ingresar el importe manualmente',
        cuota: 'Cuota {cuota}',
        cuota_nueva: 'Cuota nueva',
        remesa: '# Remesa en Cia',
        remesa_short: '# Remesa',
        exchange_rate: 'Tipo de cambio',
        exchange_rate_short: 'T. cambio',
        comision_pagos: 'Comisiones relacionadas a esta imputación',
        comision_warning: 'Hay comisiones asociadas al pago eliminado. Las puede eliminar manualmente',
        descargar_recibo: 'Descargar recibo',
        cupon: '# Cupon',
        descargar_cupon: 'Descargar cupon de pago',
    }
}