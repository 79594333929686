import { cotizar } from './cotizador/Cotizar';
import { config } from './cotizador/automotor/config';
import { emisiones } from './cotizador/automotor/emisiones';
import { historial } from './cotizador/automotor/historial';
import { parametrosGenerales } from './cotizador/automotor/parametrosGenerales';
import { presupuesto } from './cotizador/automotor/presupuesto';
import { prospectos } from './cotizador/prospectos/prospectos';

export const cotizador = () => {
	return {
		prospectos: prospectos(),
		cotizar: cotizar(),
        stats: {
            companies: {
                header: 'Estadísticas por compañía',
                title: 'Estadísticas de Cotizaciones',
                exitosas: 'Cotizaciones exitosas por compañía',
            },
            usuarios: {
                header: 'Estadísticas por Usuario',
                title: 'Estadísticas de Cotizaciones',
                exitosas: 'Cotizaciones exitosas por usuario y compañía',
            },
            search: {
                title: 'Filtros de estadísticas',
            },
            toast: {
                warning: {
                    summary: 'Filtros incompletos',
                    filters: 'Por favor, completa todos los filtros antes de continuar.',
                },
                error: {
                    summary: 'Error al cargar estadísticas',
                    stats: 'Hubo un error al cargar las estadísticas. Por favor, intenta nuevamente.',
                },
            },
            avg_seconds: 'Tiempo promedio de cotización',
            max_seconds: 'Tiempo máximo de cotización',
        },
		automotor: {
			parametrosGenerales: parametrosGenerales(),
			presupuesto: presupuesto(),
			historial: historial(),
			emisiones: emisiones(),
			config: config(),
		},
		components: {
			accesoriosTable: {
				tooltips: {
					agregar: 'Agregar',
				},
				columns: {
					sumaAsegurada: 'Suma Asegurada',
				}
			}
		}
	};
};
