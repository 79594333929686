export const empleados = () => {
	return {
		contextMenu: {
			editEmployee: 'Ver / Editar Empleado/Datero',
			editCommissions: 'Gestionar Comisiones',
			viewAssociations: 'Ver acuerdos comerciales por código de Productor',
			delete: 'Borrar Empleado/Datero',
		},
		form: {
			new_employee: 'Nuevo Empleado con acceso al sistema',
			new_dater: 'Nuevo Datero (sin acceso al sistema)',
			edit_employee: 'Editar Empleado {employee}',
			edit_dater: 'Editar Datero {employee}',
		},
		toast: {
			success: {
				updatedUser: 'Usuario actualizado',
				createdUser: 'Usuario creado',
				updatedEmployee: 'Datos actualizados',
				createdEmployee: 'Intermediario creado',
			},
			error: {
				summary: 'Error',
				detail: 'Error buscando intermediarios',
			},
		},
		confirmDialog: {
			createUser: 'Se creará un usuario Empleado en Mi Sociedad, con acceso a todos mis datos. IMPORTANTE: este usuario tendrá un costo que se sumará a su plan actual. ¿Confirmar?',
			deleteEmployee: '¿Está seguro que desea eliminar al intermediario?',
		},
		searchSideBar: {
			employeeHeader: 'Búsqueda de Empleados',
			daterHeader: 'Búsqueda de Dateros',
		},
		saveCancelForm: {
            perceive_commission_tooltip: 'Al momento de asignarlo a la póliza, se agrega en el listado de participantes que comisionan en la misma.',
            perceive_commission_tooltip_short: 'Percibe comisión',
            autoassigned: 'Autoasignación en ramos:',
            autoassign: 'El {tipo} se asignará automáticamente a las pólizas de los ramos seleccionados al momento de la carga de la póliza.',
            participates_ramos: 'Autoasignar en los siguientes Ramos',
            comisiona: 'Percibe comisión',
            no_comisiona: 'No percibe comisión',
			email: 'Email (aqui recibirá las instrucciones de acceso)',
			perception_percentage: 'Descuento % sobre bruto de comisión (general)',
			legend: 'Descuento % sobre bruto de comisión (por Compañía)',
			perception: 'Porcentaje',
		},
		employeeCommissions: {
			dialog: 'Acuerdos Comerciales de {employee}',
			legend: 'Acuerdos comerciales generales',
		},
		employeeBusinessAssociations: 'Acuerdos Comerciales por código de productor',
		datatable: {
			daters: 'Dateros (sin acceso al sistema)',
			column: {
				headerDater: 'Datero',
				perception: '{perception} % de percepción general',
                perception_cia:'{perception} % de percepción en {cia}',
				businessAgreementsHeader: 'Acuerdos Comerciales',
				businessAgreements: 'Acuerdos Generales',
				premio: '{premio}% sobre premio',
				prima: '{prima}% sobre prima',
				ayuda: '{ayuda}% de ayuda/extra',
				denominacion: 'en ramo {denominacion}',
				employeeBusinessAssociation: 'Tiene Acuerdos específicos para los códigos de Productor',
				code: 'Código {codigo} - {denominacion}',
			},
		},
	};
};
