import { createFilter } from '@helpers/filtersHelper';
import { saveGenericEntity, useDelete, useGet, useGetAll, usePost } from '@service/AxiosBaseService';
import { ref } from 'vue';
import { lateralToast } from '@service/MessageService';

export const resourceName = '/api/people_groups';
export const resultKey = 'people_group';
export const selectedGroup = ref(null);

export const filters = ref({
	nombre: createFilter('nombre', 'string', 'like', 'name'),
	descripcion: createFilter('descripcion', 'string', 'like', 'description'),
});

export const defaultFilter = {
	nombre: createFilter('nombre', 'string', 'like', 'name'),
};

export const createEmptyPeopleGroup = () => {
	return {
		id: null,
		nombre: null,
		description: null,
		people: [],
	};
};

export const getAll = (params) => {
	return useGetAll(params, resourceName);
};

export const saveGroup = async (selectedGroup) => {
	// hago una copia de la entidad asi le puedo sacar las associations vacias pre submit
	let groupCopy = JSON.parse(JSON.stringify(selectedGroup.value));
	// groupCopy = cleanEmptyAssociations(groupCopy);
	//groupCopy = setSimpleAssociations(groupCopy);
	return await saveGenericEntity(selectedGroup, { people_group: groupCopy }, resourceName, resultKey);
};

export const getGroup = async (id) => {
	return await useGet(`${resourceName}/${id}`);
};

export const deleteGroup = async (toast) => {
	await useDelete(`${resourceName}/${selectedGroup.value.id}`);
	lateralToast.value = {
		severity: 'success',
		summary: 'Grupo de personas',
		detail: 'Grupo de personas eliminado',
	};
};

export const addPersonToGroup = async (personId, groupId) => {
	await usePost(`${resourceName}/${groupId}/add`, { person_id: personId });
	lateralToast.value = {
		severity: 'success',
		summary: 'Grupo de personas',
		detail: 'Persona agregada al grupo',
	};
};

export const removePersonFromGroup = async (personId, groupId) => {
	await usePost(`${resourceName}/${groupId}/remove`, { person_id: personId });
	lateralToast.value = {
		severity: 'success',
		summary: 'Grupo de personas',
		detail: 'Persona eliminada del grupo',
	};
};
